<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>주의작업이력</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="margin: 20px auto 0px 140px">
				<form @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<!-- 달력 -->
						<div class="col-md-3" style="width: 35%; margin-left: -15px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchWarnWorkOccurDtm }}</label>
								<input type="date" class="form-control" v-model="searchVM.warnWorkOccurDtmStat" />
								&nbsp;~&nbsp;
								<input type="date" class="form-control" v-model="searchVM.warnWorkOccurDtmEnd" :min="searchVM.warnWorkOccurDtmStat" :max="maxDay" />
							</div>
						</div>
						<!-- 현장 -->
						<div class="col-md-3" style="margin-left: -30px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchSite }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isDisabledSearchSite">
									<option value="">전체</option>
									<option v-for="item in options.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
						<!-- CCTV -->
						<div class="col-md-3" style="margin-left: 18px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchCctv }}</label>
								<select2 v-model="searchVM.cctvId">
									<option value="">전체</option>
									<option v-for="item in options.cctvOptions" :key="item.cctvId" :value="item.cctvId">
										{{ item.cctvNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<!-- 대상물 -->
						<div class="col-md-3" style="width: 290px; margin-left: -28px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchObject }}</label>
								<select2 v-model="searchVM.objectId">
									<option value="">전체</option>
									<option v-for="item in options.warnWorkOptions" :key="item.objectId" :value="item.objectId">
										{{ item.objectNm }}
									</option>
								</select2>
							</div>
						</div>
						<div class="col-md-3" style="width: 320px; margin-left: -3px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchVideoWarnActnDvsnCd }}</label>
								<select2 v-model="searchVM.videoWarnActnDvsnCd">
									<option value="">전체</option>
									<option v-for="item in options.videoWarnActnDvsnCdOption" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
						<!-- 조회 버튼 -->
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b>
									<i class="icon-search4"></i>
								</b>
								조회
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- Grid -->
		<div>
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>
		</div>
		<div class="mt-10 mb-15 text-right"></div>
		<form id="detailsForm" class="form-horizontal form-validate-jquery" v-show="isEditMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<b>프레임 정보 ({{ detailsItem.cctvNmAndInstallPlace }})</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li>
								<a @click="pannelHidden" data-action="collapse"></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="border" style="width: 1020px; height: auto; max-height: 800px; overflow-y: auto">
						<div style="display: inline-block; text-align: center" v-for="(item, index) in detailsItem.inqWarnWorkOccurImgOutVOs" :key="index">
							<div v-if="index < 30">
								<img
									:src="item.imgData"
									style="width: 200px; height: 112.5px; object-fit: contain; padding: 5px; display: block; cursor: pointer"
									@click="warnWorkOccurImgClicked(item, index)"
								/>
								<span>{{ item.imgSaveDtm }}</span>
							</div>
						</div>
					</div>
					<div class="border" style="width: 358px; margin: 0 0 20px 20px">
						<div class="rhtBox">
							<div><h3>대상물</h3></div>
							<div class="objectBox" v-for="(object, index) in detailsItem.objectNmsAllSequences" :key="index">
								<div style="font-size: 17px; margin-bottom: 10px; padding-left: 15px; margin-top: 15px; margin-left: 20px">
									{{ object }}
								</div>
								<div class="border-bottom" style="margin-top: 60px"></div>
							</div>
						</div>
						<div class="border-bottom"></div>
						<div class="rhtBox" style="border: none">
							<div><h3>작업 이력</h3></div>
							<div style="margin-top: 30px; margin-left: 20px; padding-left: 15px">
								- {{ detailsItem.warnWorkOccurDtm }} / {{ detailsItem.videoWarnActnDvsnCdNm }}
							</div>
						</div>
						<div class="border-bottom" style="margin-bottom: 10px"></div>
						<div style="text-align: right; margin-bottom: 10px; margin-right: 20px" v-if="detailsItem.videoWarnActnDvsnCd != '04'">
							<button
								class="btn bg-primary"
								style="flex: 1; margin: 0px 5px"
								@click.stop="misdetection"
								v-if="detailsItem.videoWarnActnDvsnCd != '05'"
							>
								오인식
							</button>
							<button
								class="btn bg-primary"
								style="flex: 1; margin: 0px 5px"
								@click.stop="deMisdetection"
								v-if="detailsItem.videoWarnActnDvsnCd == '05'"
							>
								오인식 취소
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div ref="popupImg" class="modal">
			<!-- Modal content -->
			<div class="modal-content">
				<span @click="popupImgClose" class="close">&times;</span>
				<div style="height: 30px"></div>
				<div class="row" style="display: flex; align-items: center; justify-content: center">
					<div class="col-md-3">
						<div
							style="
								flex: 1;
								overflow-y: auto;
								flex-direction: column;
								height: 720px;
								max-height: 720px;
								border-right: 1px solid #ddd;
								padding-right: 15px;
							"
							left
						>
							<div
								style="position: relative; overflow: hidden"
								v-for="(item, index) in detailsItem.inqWarnWorkOccurImgOutVOs"
								:key="index"
								:class="{ active: index == activePicture }"
								@click="selectPicture(index)"
								:tabindex="index"
								@keydown.up="priviousImage()"
								@keydown.left="priviousImage()"
								@keydown.down="nextImage()"
								@keydown.right="nextImage()"
								photo
							>
								<img :src="detailsItem.inqWarnWorkOccurImgOutVOs[index].imgData" />

								<div>
									<div style="position: absolute; width: 100%; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%)"></div>
								</div>
							</div>
						</div>
					</div>
					<div align="center" class="col-md-9">
						<div align="center" right style="flex: 5 1 0%; height: inherit; justify-content: center">
							<img id="popupImg" :src="this.popupImg.imgData" style="width: 910px; max-height: 520px; object-fit: contain; display: block" />
						</div>
						<div align="center" right style="flex: 5 1 0%; margin-left: 15px; height: inherit; justify-content: center">
							<div class="row">
								<div class="mBox col-md-3">
									<div><h4>장소 및 시각</h4></div>
									<div class="border-bottom"></div>
									<div style="margin-top: 10px; margin-left: 20px">{{ detailsItem.cctvNmAndInstallPlace }}</div>
									<div style="margin-top: 10px; margin-left: 20px">{{ popupImg.imgSaveDtm }}</div>
								</div>
								<div class="mBox col-md-9">
									<div><h4>대상물</h4></div>
									<div class="border-bottom"></div>
									<div style="display: flex; justify-content: center">
										<div v-for="(object, index) in popupImg.objects" :key="index" style="display: inline-block; max-width: 50%; margin: 10px">
											<div style="font-size: 17px; text-align: center">{{ object }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import extend from 'extend';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';
const warnWorkOccurUrl = apiIndex.warnWorkOccur;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			pageListApi: warnWorkOccurUrl.inqWarnWorkOccur,
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		options: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'objectNm', title: '대상물명' },
			{ field: 'warnWorkOccurDtm', title: '발생시각', align: 'center' },
			{ field: 'siteNm', title: '현장명' },
			{ field: 'cctvNmAndInstallPlace', title: 'CCTV' },
			{
				field: 'videoWarnActnDvsnCd',
				title: '주의 조치 코드',
				dataFormat: 'optionMap',
				align: 'center',
			},
		],
		detailsFieldMap: {
			searchSite: '현장명',
			searchWarnWorkOccurDtm: '발생시각',
			searchCctv: 'CCTV명',
			searchObject: '대상물명',
			searchVideoWarnActnDvsnCd: '주의 조치 코드',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		popupImg: {},
		isDisabledSearchSite: true,
		activePicture: 0,
		isImagePoppedUp: false,
		warnWorkOccurSequenceMap: {},
		//warnWorkOccurDtmEnd의 지정 가능한 최대 날짜
		maxDay: '',
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('warnWorkOccurPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		this.searchVM = {
			warnWorkOccurDtmStat: moment().format('YYYY-MM-DD'),
			warnWorkOccurDtmEnd: moment().format('YYYY-MM-DD'),
			siteId: this.pageParam.loginUserSiteId,
			cctvId: '',
			warnWorkId: '',
			objectId: '',
			videoWarnActnDvsnCd: '01',
		};

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.options = {
			siteOptions: this.pageParam.siteOptions,
			cctvOptions: this.pageParam.cctvOptions,
			warnWorkOptions: this.pageParam.warnWorkOptions,
			videoWarnActnDvsnCdOption: commonCodeList.filter(item => item.grpCd == 'video_warn_actn_dvsn_cd'),
		};

		this.gridColumns.filter(item => item.field == 'videoWarnActnDvsnCd')[0].mapData = this.options.videoWarnActnDvsnCdOption;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.isDisabledSearchSite = this.pageParam.loginUserRolecd === '00' ? false : true;
	},
	mounted() {
		this.search();
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			return {
				detailsItem: this.isEditMode ? this.detailsValidationRule.edit : this.detailsValidationRule.create,
			};
		},
		isDisabled() {
			return false;
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== undefined) {
					this.$axios
						.post(warnWorkOccurUrl.inqWarnWorkOccurCctvInfo, { siteId: this.searchVM.siteId })
						.then(
							function (r) {
								this.options.cctvOptions = r.data;
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.warnWorkOccurDtmStat': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
		// 팝업 모달이 생겨났을 때, 해당 이미지에 포커스를 두도록(키보드로 이미지를 바로 이동시킬 수 있게 하기 위함)
		isImagePoppedUp() {
			if (this.isImagePoppedUp == true) {
				this.$nextTick(function () {
					let selectedImage = document.getElementsByClassName('active')[0];
					selectedImage.focus();
				});
			}
		},
		// 활성화된 이미지가 변경됐을 때 이미지 데이터를 변경하기 위함
		activePicture() {
			this.popupImg = this.detailsItem.inqWarnWorkOccurImgOutVOs[this.activePicture];
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// warnWorkOccurSequenceMap 맵에 시퀀스를 키로, 대상물을 세팅
		setObjectsToSequenceMap() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(imgInfo => {
				this.detailsItem.inqWarnWorkFrameImgDetailVOs.forEach(e => {
					if (e.frameImgSeq == imgInfo.frameImgSeq && this.warnWorkOccurSequenceMap[imgInfo.frameImgSeq] == undefined) {
						this.$set(this.warnWorkOccurSequenceMap, imgInfo.frameImgSeq, new Array(e.objectNm));
					} else if (e.frameImgSeq == imgInfo.frameImgSeq) {
						this.setObjectNmstWithoutDuplication(e, this.warnWorkOccurSequenceMap);
					}
				});
			});
		},
		// dangerOccurObjectsAtSequenceMap 을 활용해서 대상물과 조치사항이 있는 시퀀스에 대상물과 조치사항을 세팅
		setObjectsToDetailsItem() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(e => {
				this.$set(e, 'objects', this.warnWorkOccurSequenceMap[e.frameImgSeq]);
			});
		},
		setObjectNmstWithoutDuplication(vo, sequenceMap) {
			if (sequenceMap[vo.frameImgSeq] == undefined) {
				return;
			}
			let filter = sequenceMap[vo.frameImgSeq].filter(e => e == vo.objectNm);
			if (filter.length == 0) {
				sequenceMap[vo.frameImgSeq].push(vo.objectNm);
			}
		},
		//모달창에서 키 다운으로 이전 이미지로 이동
		priviousImage() {
			if (this.activePicture > 0) {
				this.activePicture = this.activePicture - 1;
				this.selectPicture(this.activePicture);
			}
		},
		//모달창에서 키 다운으로 다음 이미지로 이동
		nextImage() {
			if (this.activePicture < this.detailsItem.inqWarnWorkOccurImgOutVOs.length - 1) {
				this.activePicture = this.activePicture + 1;
				this.selectPicture(this.activePicture);
			}
		},
		//모달창에서 이미지를 선택했을 때
		selectPicture(index) {
			if (index < 0 || index >= this.detailsItem.inqWarnWorkOccurImgOutVOs.length) {
				return;
			}
			this.activePicture = index;
			// this.pictureNum = index + 1;
			//스크롤바를 내리는 용도
			let distance = (index - 10) * 10;
			$('.modal-content [left]').animate({ scrollTop: index * 130 - 170 + distance }, 50);
		},
		//검색적용
		search() {
			this.searchState = extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return extend(true /*deep*/, param, this.searchState || {});
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			this.detailsItem = {};
			this.warnWorkOccurSequenceMap = {};
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(warnWorkOccurUrl.inqOneWarnWorkOccur, {
						siteId: selectedRowItem.siteId,
						cctvId: selectedRowItem.cctvId,
						objectId: selectedRowItem.objectId,
						warnWorkOccurSeq: selectedRowItem.warnWorkOccurSeq,
						strtFrameImgSeq: selectedRowItem.strtFrameImgSeq,
						endFrameImgSeq: selectedRowItem.endFrameImgSeq,
					})
					.then(
						function (response) {
							this.detailsItemOriginal = extend(true, {}, response.data);
							this.detailsItem = extend(true, {}, response.data);
							this.detailsItem.cctvNmAndInstallPlace = selectedRowItem.cctvNmAndInstallPlace;
							this.setAllFramesToImageData();

							// 대상물 분리
							// if (this.detailsItem.objectNms) {
							// 	this.detailsItem.objectNms = this.detailsItem.objectNms.split(',');
							// }
							// 구분코드 명칭 가져오기
							for (let index = 0; index < this.options.videoWarnActnDvsnCdOption.length; index++) {
								if (this.options.videoWarnActnDvsnCdOption[index].value === this.detailsItem.videoWarnActnDvsnCd) {
									this.detailsItem.videoWarnActnDvsnCdNm = this.options.videoWarnActnDvsnCdOption[index].text;
									break;
								} else {
									this.detailsItem.videoWarnActnDvsnCdNm = '';
								}
							}
							// warnWorkOccurSequenceMap 맵에 시퀀스를 키로, 대상물을 세팅
							this.setObjectsToSequenceMap();
							// warnWorkOccurSequenceMap 을 활용해서 대상물과 조치사항이 있는 시퀀스에 대상물을 세팅
							this.setObjectsToDetailsItem();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//이미지 데이터 세팅
		setAllFramesToImageData() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(e => {
				this.$axios.post(warnWorkOccurUrl.imgOneWarnWorkOccur, { fileName: e.imgSaveFileNm }).then(response => {
					e.imgData = 'data:image/jpg;base64,' + response.data.imgByte;
				});
			});
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		misdetection() {
			if (this.detailsItem.videoWarnActnDvsnCd === '05') {
				alert('이미 오인식 처리되었습니다.');
			} else {
				let tempItem = this.detailsItem;
				tempItem.objectNms = '';
				this.$axios
					.post(warnWorkOccurUrl.updWarnWorkOccurMisdetection, tempItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 오인식 취소
		deMisdetection() {
			if (this.detailsItem.videoWarnActnDvsnCd !== '05') {
				alert('오인식 상태가 아닙니다.');
			} else {
				this.$axios
					.post(warnWorkOccurUrl.updWarnWorkOccurDeMisdetection, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		warnWorkOccurImgClicked(item, index) {
			this.popupImg = {};
			// img 클릭 시 popup
			this.popupImg = item;
			this.$refs.popupImg.style.display = 'block';
			this.isImagePoppedUp = true;
			this.selectPicture(index);
		},
		popupImgClose() {
			// img popup 닫기
			this.$refs.popupImg.style.display = 'none';
			this.isImagePoppedUp = false;
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
	},
};
</script>

<style>
.objectBox {
	/* height: 135px; */
	min-height: 100px;
	height: auto;
}

*:focus {
	outline: none;
}
.modal-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.modal-content [photo] {
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}
.modal-content [photo] img {
	min-height: 120px;
	height: 120px;
}
.modal-content [photo].active {
	border: 5px solid #1878f3;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

.modal-content [left] [photo] {
	margin-top: 20px;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}

#rootVM > .panel .panel-body .form-group label {
	width: 70%;
	margin-top: 5px;
	max-width: 130px;
}

/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 99; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
@media (min-width: 500px) and (max-width: 1800px) {
	.modal-content {
		background-color: #fefefe;
		margin: 1% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 80%;
	}
	.modal-content [left] {
		padding: 0 15px;
		height: 85vh !important;
	}
	#popupImg {
		width: 55vw !important;
		max-height: 55vh !important;
	}
}

@media (min-width: 1801px) {
	.modal-content {
		background-color: #fefefe;
		margin: 4% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 1300px;
		height: 800px;
	}
	.modal-content [left] {
		padding: 0 15px;
	}
}
/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
label {
	text-align: right;
	margin-right: 12px;
}
.panel-heading {
	padding-left: 20px !important;
}
.border {
	border-radius: 3px;
	border: 1px solid #ddd;
	float: left;
}
.border-bottom {
	width: 90%;
	margin: auto;
	border-bottom: 1px solid #ddd !important;
}
.rhtBox {
	min-height: 135px;
	height: auto;
}
.rhtBox > div:first-child {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
h3 {
	font-weight: bold !important;
	margin: 0 !important;
	padding: 10px 10px 10px 15px;
}
.mBox {
	/* height: 135px; */
	height: 170px;
	border-radius: 3px;
	border: 1px solid #ddd;
	/* margin-bottom: 50px; */
	margin-bottom: 30px;
	margin-top: 30px;
}
.mBox > div:first-child {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
</style>
